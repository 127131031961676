<template>
  <div>
    <label>{{ data.label }} <required :data="data"/></label>
    <select>
      <option value="" v-if="data.required === true || data.required === 'true'"
        >---</option
      >
      <option v-for="value in max" :key="value">{{ value }}</option>
    </select>
    <div class="info" v-if="data.description">
      <icon id="circle-info" /> &nbsp;
      <span v-html="data.description.split('\n').join('<br />')"></span>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    required() {
      return import("./required.vue");
    }
  },
  props: {
    data: Object
  },
  data() {
    return {
      max: this.getMax()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getMax() {
      if (this.data.values === "auto") {
        return 10;
      }
      return this.data.values * 1;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
label
  font-weight: bold
select
  display: block
  margin: 10px 0
  box-sizing: border-box
  padding: 10px
  border: 1px solid $grey
  border-radius: 7px
  &:active, &:focus
    outline: none
.info
  margin-top: 3px
  color: $info
  font-size: 9pt
ul
  margin: 0
  padding: 0
  list-style: none
  display: flex
  flex-direction: column
  margin-top: 10px
  li
    a
      text-decoration: none
      color: $dark-grey
      .icon
        color: $success
</style>
